var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticClass: "invoice-status",
    attrs: {
      chips: "",
      disabled: _vm.isDisabled,
      "hide-details": true,
      "hide-selected": true,
      items: _vm.items,
      value: _vm.value,
    },
    on: { change: _vm.onChange },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function () {
          return [
            _c(
              "v-chip",
              {
                staticClass: "font-weight-bold",
                attrs: {
                  color: _vm.statusConfig[_vm.value]?.color,
                  small: "",
                  "text-color": _vm.computedTextColor,
                  label: "",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.statusConfig[_vm.value]?.text || _vm.value) +
                    " "
                ),
                !_vm.isDisabled
                  ? _c(
                      "v-avatar",
                      { attrs: { right: "" } },
                      [_c("v-icon", [_vm._v("mdi-chevron-down")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }