<template>
  <div :style="{ '--header-bg-color': color }">
    <template v-if="$slots.top">
      <div class="text-right mb-4">
        <slot name="top"></slot>
      </div>
    </template>

    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :fixed-header="fixedHeader"
      :height="height"
      :items="rows"
      class="tt-table elevation-1"
      :disable-sort="!computedSortable"
      v-bind="paginationProps"
      @pagination="handlePagination"
      :show-expand="showExpansionColumn"
      :footer-props="{
        'items-per-page-options': computedItemsPerPageOptions,
      }"
      :hide-default-footer="computedHideDefaultFooter"
      :show-select="showSelect"
      :value="value"
      @input="$emit('input', $event)"
    >
      <template v-slot:item="{ item, index }">
        <tr :class="getRowClass(index)">
          <td v-if="showSelect">
            <v-checkbox :input-value="isRowSelected(item)" @click.stop="toggleSelection(item)"></v-checkbox>
          </td>

          <td
            v-for="header in headers"
            :key="header.text"
            :class="{
              'text-right': header.align === 'end',
              grey: header.disabled,
              'lighten-3': header.disabled,
              'grey--text': header.disabled,
              'text--lighten-1': header.disabled,
            }"
          >
            <slot :name="'column_' + header.value" v-bind:header="header" v-bind:item="item">
              <template v-if="header.type === 'chips' && header.render">
                <v-chip :color="chipValue({ header, item, type: 'color' })" small class="font-weight-bold">
                  {{ chipValue({ header, item, type: 'text' }) }}
                </v-chip>
              </template>

              <template v-if="header.type === 'expansion'">
                <v-btn v-if="item.showExpansion" icon @click="toggleExpanded(item)">
                  <v-icon>{{ expanded[item.id] ? `mdi-chevron-up` : `mdi-chevron-down` }}</v-icon>
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  v-if="header.click"
                  @click="header.click(item)"
                  text
                  small
                  color="primary"
                  class="font-weight-bold"
                  >{{ getRowText({ header, item }) }}</v-btn
                >

                <span v-else-if="header.disabled && !showDisabled"></span>

                <span :class="getClasses({ header, item })" v-else>
                  {{ getRowText({ header, item }) }}
                </span>
              </template>
            </slot>
          </td>

          <td v-if="menuOptions.length > 0" class="text-right">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <template v-for="(option, index) in menuOptions">
                  <v-list-item
                    :disabled="option.disabled ? option.disabled(item) : false"
                    v-if="option.show ? option.show(item) : true"
                    :key="index"
                    @click="option.click(item)"
                  >
                    <v-icon v-if="option.icon" left>{{ option.icon }}</v-icon>
                    <v-list-item-title>{{ option.text }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <tr v-if="expanded[item.id]">
          <td :colspan="headers.length + 1" class="px-0">
            <slot name="expansion" v-bind:item="item"></slot>
          </td>
        </tr>
      </template>

      <template v-slot:foot v-if="$slots.footer">
        <slot name="footer"></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { colors } from '@/util';
export default {
  props: {
    serverSide: { type: Boolean, default: false },
    headers: { type: Array, default: () => [] },
    rows: { type: Array, default: () => [] },
    menuOptions: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 20 },
    currentPage: { type: Number, default: 0 },
    totalItems: { type: Number, default: 0 },
    sortable: { type: Boolean, default: false },
    showSelect: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    color: { type: String, default: colors.lightBlue },
    showDisabled: { type: Boolean, default: true },
    fixedHeader: { type: Boolean, default: false },
    height: { type: Number, default: undefined },
    expandRow: { type: Boolean, default: false },
  },
  data() {
    return {
      prevItemsPerPage: this.itemsPerPage,
      prevPage: this.currentPage,
      expanded: {},
    };
  },
  computed: {
    computedHideDefaultFooter() {
      return this.itemsPerPage === -1;
    },
    computedItemsPerPageOptions() {
      if (this.itemsPerPage === -1) return [-1];
      return [20, 50, 100, 200, 500];
    },
    computedSortable() {
      return this.sortable || !this.serverSide;
    },
    paginationProps() {
      if (this.serverSide) {
        return {
          'items-per-page': this.itemsPerPage,
          'current-page': this.currentPage,
          'server-items-length': this.totalItems,
        };
      }
      return {};
    },
    computedHeaders() {
      let headers = [...this.headers];

      if (this.menuOptions && this.menuOptions.length > 0) {
        headers.push({ text: '', value: 'actions', sortable: false });
      }

      return headers;
    },
    showExpansionColumn() {
      return this.headers.some((header) => header.type === 'expansion');
    },
  },
  methods: {
    getClasses({ item, header }) {
      if (typeof header.classes === 'function') return header.classes(item);

      return header.classes || '';
    },
    isRowSelected(item) {
      return this.value.some((selectedItem) => selectedItem.id === item.id);
    },
    toggleSelection(item) {
      const newValue = [...this.value];
      const index = newValue.findIndex((selectedItem) => selectedItem.id === item.id);
      if (index >= 0) {
        newValue.splice(index, 1);
      } else {
        newValue.push(item);
      }
      this.$emit('input', newValue);
    },
    isNumber(value) {
      return typeof value === 'number';
    },
    getRowClass(index) {
      return index % 2 === 0 ? 'row-color-even' : 'row-color-odd';
    },
    getValueByPath({ item, path }) {
      if (typeof path === 'function') {
        return path(item);
      }

      const value = path.split('.').reduce((accumulator, key) => {
        return accumulator === undefined || accumulator === null ? null : accumulator[key];
      }, item);

      return value != null ? value : '';
    },
    getHeaderValue({ item, header }) {
      return header.render(item);
    },
    chipValue({ item, header, type = 'text' }) {
      const headerValue = this.getHeaderValue({ header, item });
      if (type === 'color') {
        return headerValue.color || '';
      }
      return headerValue.text;
    },
    getRowText({ header, item }) {
      return `${header.prefix || ''}${this.getValueByPath({ item, path: header.render || header.value })}`;
    },
    toggleExpanded(item) {
      this.$set(this.expanded, item.id, !this.expanded[item.id]);
    },
    allRowsExpansion(value) {
      if (value) {
        this.rows.forEach((row) => {
          this.$set(this.expanded, row.id, value);
        });
        return;
      }
      this.expanded = {};
    },
    async handlePagination({ itemsPerPage, page }) {
      this.$emit('pagination', { itemsPerPage, page });
    },
  },
  watch: {
    expandRow(value) {
      this.allRowsExpansion(value);
    },
    loading(value) {
      // when navigating to another page, reset the rows expansion after loading the data
      if (!value) this.allRowsExpansion(this.expandRow);
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header,
::v-deep .v-data-table-header th {
  background-color: var(--header-bg-color) !important;
}

.row-color-odd {
  background-color: #f5f6f7;
}

.row-color-even {
  background-color: #fff;
}

.text-break {
  white-space: pre-wrap;
}
</style>
