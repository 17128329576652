var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-bordered fill-height",
      style: { borderColor: _vm.computedColor },
      attrs: { flat: "" },
    },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { "no-gutters": "" } },
        [
          _vm.$slots.left
            ? _c(
                "v-col",
                {
                  class: `text-h4 text-center card-bordered-left fill-height ${_vm.style.padding}`,
                  style: { backgroundColor: _vm.computedColor },
                  attrs: { cols: "2" },
                },
                [_vm._t("left")],
                2
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              class: `card-bordered-body fill-height ${_vm.style.padding}`,
              attrs: { cols: _vm.$slots.left ? 10 : 12 },
            },
            [_vm._t("body")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }