var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-center align-center" },
    [
      _c("v-progress-circular", {
        attrs: {
          size: _vm.sizeMap[_vm.size],
          width: _vm.widthMap[_vm.size],
          color: "primary",
          indeterminate: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }