var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoadingPresets
        ? _c(
            "div",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-three-line",
                  loading: _vm.isLoadingPresets,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("span", [_vm._v(_vm._s(_vm.customFields.name))]),
              _c(
                "draggable",
                {
                  on: { sort: _vm.emitSequenceChanges },
                  model: {
                    value: _vm.customFields.fields,
                    callback: function ($$v) {
                      _vm.$set(_vm.customFields, "fields", $$v)
                    },
                    expression: "customFields.fields",
                  },
                },
                [
                  _c(
                    "transition-group",
                    _vm._l(_vm.customFields.fields, function (row) {
                      return _c(
                        "div",
                        { key: row.uniqId, staticClass: "mb-5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(row.type.label)),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "subtitle text-caption font-italic",
                                  },
                                  [
                                    _vm._v(
                                      " (" +
                                        _vm._s(
                                          row.required ? "Required" : "Optional"
                                        ) +
                                        " " +
                                        _vm._s(
                                          row.lengthValidation.isPresent
                                            ? ", has length validation"
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          row.valueValidation.isPresent
                                            ? ", has value validation"
                                            : ""
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-col", { attrs: { cols: "8" } }, [
                                _c("div", { staticClass: "caption" }, [
                                  _vm._v("Field Name / Question"),
                                ]),
                                _c("div", {}, [_vm._v(_vm._s(row.label))]),
                              ]),
                              _c("v-col", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-5",
                                        attrs: { fab: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editField(row)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-pencil")])],
                                      1
                                    ),
                                    row?.options
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-5",
                                            attrs: { fab: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.optionSettings(row)
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("mdi-cogs")])],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "error", fab: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeField(row)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-delete")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          row?.options
                            ? _c(
                                "v-expansion-panels",
                                { staticClass: "pa-5" },
                                [
                                  _c(
                                    "v-expansion-panel",
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(" Options    "),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: row.options.length,
                                                expression:
                                                  "row.options.length",
                                              },
                                            ],
                                            staticClass: "italic",
                                          },
                                          [
                                            _vm._v(
                                              "(+" +
                                                _vm._s(row.options.length) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                _vm._l(
                                                  row.options,
                                                  function (option) {
                                                    return _c(
                                                      "v-row",
                                                      { key: option.label },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.label
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                option.value
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "2",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  icon: "",
                                                                  small: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeOption(
                                                                        row,
                                                                        option
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showCustomFieldSetup = true
                            },
                          },
                        },
                        [_vm._v("Add Field")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: { click: _vm.saveField },
                        },
                        [_vm._v("Save Field")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          on: { input: _vm.dialogStatus },
          model: {
            value: _vm.showCustomFieldSetup,
            callback: function ($$v) {
              _vm.showCustomFieldSetup = $$v
            },
            expression: "showCustomFieldSetup",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Custom Field Settings")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.isFormValid,
                        callback: function ($$v) {
                          _vm.isFormValid = $$v
                        },
                        expression: "isFormValid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.handleErrors(
                                _vm.$v.customFieldMetadata.label
                              ),
                              label: "Field Name / Question",
                            },
                            model: {
                              value: _vm.customFieldMetadata.label,
                              callback: function ($$v) {
                                _vm.$set(_vm.customFieldMetadata, "label", $$v)
                              },
                              expression: "customFieldMetadata.label",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              "error-messages": _vm.handleErrors(
                                _vm.$v.customFieldMetadata.type
                              ),
                              items: _vm.fieldTypes,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Field Type",
                              "return-object": "",
                              "single-line": "",
                            },
                            on: { change: _vm.clearValidationData },
                            model: {
                              value: _vm.customFieldMetadata.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.customFieldMetadata, "type", $$v)
                              },
                              expression: "customFieldMetadata.type",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              "error-messages": _vm.handleErrors(
                                _vm.$v.customFieldMetadata.colspan
                              ),
                              items: _vm.colspans,
                              "item-text": "label",
                              "item-value": "value",
                              label: "Field Width",
                              "single-line": "",
                            },
                            model: {
                              value: _vm.customFieldMetadata.colspan,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customFieldMetadata,
                                  "colspan",
                                  $$v
                                )
                              },
                              expression: "customFieldMetadata.colspan",
                            },
                          }),
                          _c("v-divider"),
                          _c(
                            "div",
                            { staticClass: "mt-5" },
                            [
                              _c("span", { staticClass: "caption" }, [
                                _vm._v("Field Validations"),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: { label: "Required" },
                                        model: {
                                          value:
                                            _vm.customFieldMetadata.required,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customFieldMetadata,
                                              "required",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "customFieldMetadata.required",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.customFieldMetadata.type
                                                ?.label ===
                                              _vm.fields.TEXT.label,
                                            expression:
                                              "customFieldMetadata.type?.label === fields.TEXT.label",
                                          },
                                        ],
                                        attrs: {
                                          "error-messages":
                                            _vm.lengthValidation(
                                              _vm.$v.customFieldMetadata
                                                .lengthValidation
                                            ),
                                          label: "Length Validation",
                                        },
                                        model: {
                                          value:
                                            _vm.customFieldMetadata
                                              .lengthValidation.isPresent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customFieldMetadata
                                                .lengthValidation,
                                              "isPresent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "customFieldMetadata.lengthValidation.isPresent",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.customFieldMetadata
                                                  .lengthValidation.isPresent,
                                              expression:
                                                "customFieldMetadata.lengthValidation.isPresent",
                                            },
                                          ],
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              label: "Min Length",
                                            },
                                            model: {
                                              value:
                                                _vm.customFieldMetadata
                                                  .lengthValidation.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customFieldMetadata
                                                    .lengthValidation,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customFieldMetadata.lengthValidation.min",
                                            },
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              label: "Max Length",
                                            },
                                            model: {
                                              value:
                                                _vm.customFieldMetadata
                                                  .lengthValidation.max,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customFieldMetadata
                                                    .lengthValidation,
                                                  "max",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customFieldMetadata.lengthValidation.max",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: [
                                              _vm.fields.NUMBER.label,
                                              _vm.fields.DATE.label,
                                            ].includes(
                                              _vm.customFieldMetadata.type
                                                ?.label
                                            ),
                                            expression:
                                              "[fields.NUMBER.label, fields.DATE.label].includes(customFieldMetadata.type?.label)",
                                          },
                                        ],
                                        attrs: {
                                          "error-messages":
                                            _vm.lengthValidation(
                                              _vm.$v.customFieldMetadata
                                                .valueValidation
                                            ),
                                          label: "Value Validation",
                                        },
                                        model: {
                                          value:
                                            _vm.customFieldMetadata
                                              .valueValidation.isPresent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customFieldMetadata
                                                .valueValidation,
                                              "isPresent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "customFieldMetadata.valueValidation.isPresent",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.customFieldMetadata
                                                  .valueValidation.isPresent &&
                                                _vm.customFieldMetadata.type
                                                  ?.label ===
                                                  _vm.fields.NUMBER.label,
                                              expression:
                                                "\n                      customFieldMetadata.valueValidation.isPresent &&\n                      customFieldMetadata.type?.label === fields.NUMBER.label\n                    ",
                                            },
                                          ],
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              label: "Min Value",
                                            },
                                            model: {
                                              value:
                                                _vm.customFieldMetadata
                                                  .valueValidation.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customFieldMetadata
                                                    .valueValidation,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customFieldMetadata.valueValidation.min",
                                            },
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              label: "Max Value",
                                            },
                                            model: {
                                              value:
                                                _vm.customFieldMetadata
                                                  .valueValidation.max,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customFieldMetadata
                                                    .valueValidation,
                                                  "max",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customFieldMetadata.valueValidation.max",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.customFieldMetadata
                                                  .valueValidation.isPresent &&
                                                _vm.customFieldMetadata.type
                                                  ?.label ===
                                                  _vm.fields.DATE.label,
                                              expression:
                                                "\n                      customFieldMetadata.valueValidation.isPresent &&\n                      customFieldMetadata.type?.label === fields.DATE.label\n                    ",
                                            },
                                          ],
                                        },
                                        [
                                          _c("date-picker", {
                                            ref: "returnDate",
                                            attrs: {
                                              label: "Min Date",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.customFieldMetadata
                                                  .valueValidation.min,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customFieldMetadata
                                                    .valueValidation,
                                                  "min",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customFieldMetadata.valueValidation.min",
                                            },
                                          }),
                                          _c("date-picker", {
                                            ref: "returnDate",
                                            attrs: {
                                              label: "Max Date",
                                              required: "",
                                            },
                                            model: {
                                              value:
                                                _vm.customFieldMetadata
                                                  .valueValidation.max,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.customFieldMetadata
                                                    .valueValidation,
                                                  "max",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "customFieldMetadata.valueValidation.max",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.process()
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.isAddingOptions,
            callback: function ($$v) {
              _vm.isAddingOptions = $$v
            },
            expression: "isAddingOptions",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Adding Options for the field")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: { label: "Label" },
                            model: {
                              value: _vm.dropdownFieldMetadata.label,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dropdownFieldMetadata,
                                  "label",
                                  $$v
                                )
                              },
                              expression: "dropdownFieldMetadata.label",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                !_vm.dropdownFieldMetadata.isValueEqualsLabel,
                              expression:
                                "!dropdownFieldMetadata.isValueEqualsLabel",
                            },
                          ],
                        },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Value" },
                            model: {
                              value: _vm.dropdownFieldMetadata.value,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dropdownFieldMetadata,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "dropdownFieldMetadata.value",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addDropdownOption()
                                },
                              },
                            },
                            [_vm._v("Add")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }