var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "80vw", persistent: "", width: _vm.width },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { dark: _vm.dark, loading: _vm.loading } },
        [
          _vm.$slots.header
            ? _c(
                "v-card-title",
                {
                  staticClass: "text-h5 lighten-4",
                  class: _vm.classColor,
                  style: _vm.styleColor,
                },
                [_vm._t("header")],
                2
              )
            : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "scrollable-text py-4" },
            [_vm._t("body")],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._t("actions"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "red darken-1",
                    loading: _vm.loading,
                    text: "",
                  },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("Close")]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }