var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-combobox",
    _vm._b(
      {
        attrs: { value: _vm.modelValue },
        on: { input: _vm.updateModelValue },
        scopedSlots: _vm._u(
          [
            _vm.showSelectAll
              ? {
                  key: "prepend-item",
                  fn: function () {
                    return [
                      _c(
                        "v-list-item",
                        {
                          attrs: { link: "", dense: "" },
                          on: { click: _vm.toggleSelectAll },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Select All" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                model: {
                                  value: _vm.allSelectedValue,
                                  callback: function ($$v) {
                                    _vm.allSelectedValue = $$v
                                  },
                                  expression: "allSelectedValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      "v-combobox",
      _vm.computedAttrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }