var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.formFields.length
        ? _c(
            "v-card",
            { staticClass: "py-4 px-2" },
            [
              _c("v-card-title", [_c("h4", [_vm._v("Custom Form Fields")])]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "ccfform",
                      staticClass: "mt-10",
                      attrs: {
                        "lazy-validation": "",
                        readonly: _vm.isReadonly,
                      },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        _vm._l(_vm.formFields, function (field) {
                          return _c(
                            "v-col",
                            { key: field.id, attrs: { cols: field.colspan } },
                            [
                              field.field_type === "Text"
                                ? _c(
                                    "v-text-field",
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: field.field_label,
                                          outlined: "",
                                          rules: [
                                            _vm.required(field.is_required),
                                            _vm.betweenLength(
                                              field.min_length,
                                              field.max_length,
                                              field.is_required,
                                              field.has_length_validation
                                            ),
                                            _vm.betweenValue(
                                              field.min_value,
                                              field.max_value,
                                              field.is_required,
                                              field.has_value_validation
                                            ),
                                          ],
                                        },
                                        model: {
                                          value:
                                            field.invoiceCustomFormFields
                                              .field_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              field.invoiceCustomFormFields,
                                              "field_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "field.invoiceCustomFormFields.field_value",
                                        },
                                      },
                                      "v-text-field",
                                      _vm.inputProps,
                                      false
                                    )
                                  )
                                : field.field_type === "Number"
                                ? _c(
                                    "v-text-field",
                                    _vm._b(
                                      {
                                        attrs: {
                                          type: "number",
                                          label: field.field_label,
                                          outlined: "",
                                          rules: [
                                            _vm.required(field.is_required),
                                            _vm.betweenValue(
                                              field.min_value,
                                              field.max_value,
                                              field.is_required,
                                              field.has_value_validation
                                            ),
                                            _vm.decimalPlaces(
                                              field.is_required
                                            ),
                                          ],
                                        },
                                        model: {
                                          value:
                                            field.invoiceCustomFormFields
                                              .field_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              field.invoiceCustomFormFields,
                                              "field_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "field.invoiceCustomFormFields.field_value",
                                        },
                                      },
                                      "v-text-field",
                                      _vm.inputProps,
                                      false
                                    )
                                  )
                                : field.field_type === "Dropdown"
                                ? _c(
                                    "v-select",
                                    _vm._b(
                                      {
                                        attrs: {
                                          label: field.field_label,
                                          items: field.field_options,
                                          "item-text": "label",
                                          "item-value": "value",
                                          rules: [
                                            _vm.required(field.is_required),
                                          ],
                                          outlined: "",
                                          dense: "",
                                        },
                                        model: {
                                          value:
                                            field.invoiceCustomFormFields
                                              .field_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              field.invoiceCustomFormFields,
                                              "field_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "field.invoiceCustomFormFields.field_value",
                                        },
                                      },
                                      "v-select",
                                      _vm.inputProps,
                                      false
                                    )
                                  )
                                : field.field_type === "Checkbox"
                                ? _c(
                                    "v-checkbox",
                                    _vm._b(
                                      {
                                        attrs: {
                                          rules: [
                                            _vm.required(field.is_required),
                                          ],
                                          label: field.field_label,
                                        },
                                        model: {
                                          value:
                                            field.invoiceCustomFormFields
                                              .field_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              field.invoiceCustomFormFields,
                                              "field_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "field.invoiceCustomFormFields.field_value",
                                        },
                                      },
                                      "v-checkbox",
                                      _vm.inputProps,
                                      false
                                    )
                                  )
                                : field.field_type === "Radio"
                                ? _c(
                                    "v-radio-group",
                                    _vm._b(
                                      {
                                        staticStyle: { "margin-top": "-10px" },
                                        attrs: {
                                          rules: [
                                            _vm.required(field.is_required),
                                          ],
                                          label: field.field_label,
                                        },
                                        model: {
                                          value:
                                            field.invoiceCustomFormFields
                                              .field_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              field.invoiceCustomFormFields,
                                              "field_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "field.invoiceCustomFormFields.field_value",
                                        },
                                      },
                                      "v-radio-group",
                                      _vm.inputProps,
                                      false
                                    ),
                                    _vm._l(
                                      field.field_options,
                                      function (option) {
                                        return _c("v-radio", {
                                          key: option.value,
                                          attrs: {
                                            label: option.label,
                                            value: option.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : field.field_type === "Date"
                                ? _c("date-picker", {
                                    attrs: {
                                      readonly: _vm.isReadonly,
                                      label: field.field_label,
                                      "hide-details": false,
                                      rules: [
                                        _vm.required(field.is_required),
                                        _vm.betweenDateValue(
                                          field.min_value,
                                          field.max_value,
                                          field.is_required,
                                          field.has_value_validation
                                        ),
                                      ],
                                    },
                                    model: {
                                      value:
                                        field.invoiceCustomFormFields
                                          .field_value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          field.invoiceCustomFormFields,
                                          "field_value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "field.invoiceCustomFormFields.field_value",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { disabled: _vm.isReadonly },
                                  on: { click: _vm.submit },
                                },
                                [_vm._v("Submit")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }