<template>
  <div>
    <div v-if="isLoadingPresets">
      <v-skeleton-loader type="list-item-three-line" :loading="isLoadingPresets" />
    </div>
    <div v-else>
      <span>{{ customFields.name }}</span>
      <draggable v-model="customFields.fields" @sort="emitSequenceChanges">
        <transition-group>
          <div v-for="row in customFields.fields" :key="row.uniqId" class="mb-5">
            <v-row>
              <v-col cols="2">
                <div class="title">{{ row.type.label }}</div>
                <div class="subtitle text-caption font-italic">
                  ({{ row.required ? 'Required' : 'Optional' }}
                  {{ row.lengthValidation.isPresent ? ', has length validation' : '' }}
                  {{ row.valueValidation.isPresent ? ', has value validation' : '' }})
                </div>
              </v-col>
              <v-col cols="8">
                <div class="caption">Field Name / Question</div>
                <div class="">{{ row.label }}</div>
              </v-col>
              <v-col>
                <div class="d-flex">
                  <v-btn @click="editField(row)" class="mr-5" fab>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn @click="optionSettings(row)" v-if="row?.options" class="mr-5" fab>
                    <v-icon>mdi-cogs</v-icon>
                  </v-btn>
                  <v-btn @click="removeField(row)" color="error" fab>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-expansion-panels v-if="row?.options" class="pa-5">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Options &nbsp;&nbsp;
                  <span class="italic" v-show="row.options.length">(+{{ row.options.length }})</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                    <v-card-text>
                      <v-row v-for="option in row.options" :key="option.label">
                        <v-col cols="2">{{ option.label }}</v-col>
                        <v-col cols="2">{{ option.value }}</v-col>
                        <v-col cols="2">
                          <v-btn color="error" icon small @click="removeOption(row, option)">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </transition-group>
      </draggable>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-btn @click="showCustomFieldSetup = true">Add Field</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="saveField" color="secondary">Save Field</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="showCustomFieldSetup" width="800" @input="dialogStatus">
      <v-card>
        <v-card-title>Custom Field Settings</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="isFormValid">
            <v-container>
              <v-text-field
                :error-messages="handleErrors($v.customFieldMetadata.label)"
                v-model="customFieldMetadata.label"
                label="Field Name / Question"
              ></v-text-field>
              <v-select
                :error-messages="handleErrors($v.customFieldMetadata.type)"
                @change="clearValidationData"
                v-model="customFieldMetadata.type"
                :items="fieldTypes"
                item-text="label"
                item-value="value"
                label="Field Type"
                return-object
                single-line
              ></v-select>
              <v-select
                v-model="customFieldMetadata.colspan"
                :error-messages="handleErrors($v.customFieldMetadata.colspan)"
                :items="colspans"
                item-text="label"
                item-value="value"
                label="Field Width"
                single-line
              ></v-select>
              <v-divider></v-divider>
              <div class="mt-5">
                <span class="caption">Field Validations</span>
                <v-row>
                  <v-col cols="4">
                    <v-checkbox v-model="customFieldMetadata.required" label="Required"></v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      :error-messages="lengthValidation($v.customFieldMetadata.lengthValidation)"
                      v-show="customFieldMetadata.type?.label === fields.TEXT.label"
                      v-model="customFieldMetadata.lengthValidation.isPresent"
                      label="Length Validation"
                    ></v-checkbox>
                    <div v-show="customFieldMetadata.lengthValidation.isPresent">
                      <v-text-field
                        type="number"
                        v-model="customFieldMetadata.lengthValidation.min"
                        label="Min Length"
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        v-model="customFieldMetadata.lengthValidation.max"
                        label="Max Length"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      :error-messages="lengthValidation($v.customFieldMetadata.valueValidation)"
                      v-model="customFieldMetadata.valueValidation.isPresent"
                      v-show="[fields.NUMBER.label, fields.DATE.label].includes(customFieldMetadata.type?.label)"
                      label="Value Validation"
                    ></v-checkbox>
                    <div
                      v-show="
                        customFieldMetadata.valueValidation.isPresent &&
                        customFieldMetadata.type?.label === fields.NUMBER.label
                      "
                    >
                      <v-text-field
                        type="number"
                        v-model="customFieldMetadata.valueValidation.min"
                        label="Min Value"
                      ></v-text-field>
                      <v-text-field
                        type="number"
                        v-model="customFieldMetadata.valueValidation.max"
                        label="Max Value"
                      ></v-text-field>
                    </div>
                    <div
                      v-show="
                        customFieldMetadata.valueValidation.isPresent &&
                        customFieldMetadata.type?.label === fields.DATE.label
                      "
                    >
                      <date-picker
                        ref="returnDate"
                        label="Min Date"
                        v-model="customFieldMetadata.valueValidation.min"
                        required
                      ></date-picker>

                      <date-picker
                        ref="returnDate"
                        label="Max Date"
                        v-model="customFieldMetadata.valueValidation.max"
                        required
                      ></date-picker>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="process()"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isAddingOptions" width="800">
      <v-card>
        <v-card-title>Adding Options for the field</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="dropdownFieldMetadata.label" label="Label"></v-text-field>
            </v-col>
            <v-col v-show="!dropdownFieldMetadata.isValueEqualsLabel">
              <v-text-field v-model="dropdownFieldMetadata.value" label="Value"></v-text-field>
            </v-col>
            <v-col>
              <v-btn @click="addDropdownOption()">Add</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { randomString, handleErrors } from '@/util';
import DatePicker from '@/components/DatePicker';
import { required } from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import configApi from '@/apis/config';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'CustomFormFieldsSetup',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    DatePicker,
    draggable,
  },
  data() {
    return {
      customFields: cloneDeep(this.value),

      customFieldMetadata: {
        required: false,
        lengthValidation: {
          isPresent: false,
          min: null,
          max: null,
        },
        valueValidation: {
          isPresent: false,
          min: null,
          max: null,
        },
        type: null,
        label: '',
        options: false,
        colspan: 12,
      },
      colspans: [
        { label: 'full-width', value: 12 },
        { label: '1/2', value: 6 },
        { label: '1/3', value: 4 },
        { label: '1/4', value: 3 },
      ],
      dropdownFieldMetadata: {
        value: '',
        label: '',
        isValueEqualsLabel: true,
      },
      isFormValid: false,
      showCustomFieldSetup: false,
      isEditing: false,
      isAddingOptions: false,
      tempFieldMetadata: {},
      tempOptionsMetadata: {},
      isLoadingPresets: false,
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters('config', { fieldTypes: 'customFormFieldTypes' }),
    ...mapState('config', { fields: 'customFormFieldTypes' }),
  },
  validations() {
    const gtLV = (value) => Number(value) > Number(this.customFieldMetadata.lengthValidation.min);
    const ltLV = (value) => Number(value) < Number(this.customFieldMetadata.lengthValidation.max);
    const gtVV = (value) => {
      return !isNaN(Number(value))
        ? Number(value) > Number(this.customFieldMetadata.valueValidation.min)
        : new Date(value) > new Date(this.customFieldMetadata.valueValidation.min);
    };
    const ltVV = (value) => {
      return !isNaN(Number(value))
        ? Number(value) < Number(this.customFieldMetadata.valueValidation.max)
        : new Date(value) < new Date(this.customFieldMetadata.valueValidation.max);
    };

    let lengthValidation = this.customFieldMetadata.lengthValidation.isPresent
      ? { min: { required, ltLV }, max: { required, gtLV }, $lazy: true }
      : { skipValidation: (_) => true };

    let valueValidation = this.customFieldMetadata.valueValidation.isPresent
      ? { min: { required, ltVV }, max: { required, gtVV }, $lazy: true }
      : { skipValidation: (_) => true };
    return {
      customFieldMetadata: {
        type: {
          required,
        },
        label: {
          required,
        },
        colspan: {
          required,
        },
        lengthValidation: lengthValidation,
        valueValidation: valueValidation,
      },
    };
  },
  watch: {
    value: {
      handler(newValue) {
        let promise = new Promise((resolve) => {
          this.customFields = cloneDeep(newValue);
          resolve();
        });

        this.isLoadingPresets = true;

        promise.then(() => {
          this.isLoadingPresets = false;
        });
      },
      deep: true,
    },
  },
  methods: {
    handleErrors,
    process() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$myalert.error('Please fill all required fields');
        return;
      }

      this.isEditing ? this.updateCustomField() : this.addCustomField();
      this.clearForm();
    },
    addCustomField() {
      if (this.customFieldMetadata.type.hasOptions) this.customFieldMetadata.options = [];
      this.customFields.fields.push({
        ...this.customFieldMetadata,
        uniqId: randomString(12),
      });
      this.$emit('input', this.customFields);
      this.showCustomFieldSetup = false;
    },
    updateCustomField() {
      Object.keys(this.customFieldMetadata).forEach((key) => {
        this.tempFieldMetadata[key] = this.customFieldMetadata[key];
      });

      if (!this.customFieldMetadata.type.hasOptions) {
        this.tempFieldMetadata.options = false;
      } else this.tempFieldMetadata.options = this.tempFieldMetadata.options || [];

      this.$emit('input', this.customFields);
      this.showCustomFieldSetup = false;
      this.tempFieldMetadata = {};
    },
    editField(field) {
      this.showCustomFieldSetup = true;
      this.isEditing = true;
      this.tempFieldMetadata = field;
      this.customFieldMetadata = cloneDeep(field);
    },
    clearForm() {
      this.$v.$reset();
      this.customFieldMetadata = {
        required: false,
        lengthValidation: {
          isPresent: false,
          min: null,
          max: null,
        },
        valueValidation: {
          isPresent: false,
          min: null,
          max: null,
        },
        type: null,
        label: '',
        options: false,
        colspan: 12,
      };
      this.isEditing = false;
      this.isSubmitted = false;
    },

    clearOptionsMetadata() {
      this.dropdownFieldMetadata = {
        value: '',
        label: '',
        isValueEqualsLabel: true,
      };
      this.isAddingOptions = false;
    },

    addDropdownOption() {
      if (this.dropdownFieldMetadata.isValueEqualsLabel)
        this.dropdownFieldMetadata.value = this.dropdownFieldMetadata.label;
      this.tempFieldMetadata.options.push({ ...this.dropdownFieldMetadata });

      this.clearOptionsMetadata();
      this.tempFieldMetadata = {};
      this.$emit('input', this.customFields);
    },

    removeField(field) {
      this.customFields.fields = this.customFields.fields.filter((f) => f.uniqId !== field.uniqId);
      this.$emit('input', this.customFields);
    },

    removeOption(field, option) {
      field.options.splice(field.options.indexOf(option), 1);
    },

    clearValidationData() {
      this.customFieldMetadata.lengthValidation.isPresent = false;
      this.customFieldMetadata.lengthValidation.min = null;
      this.customFieldMetadata.lengthValidation.max = null;
      this.customFieldMetadata.valueValidation.isPresent = false;
      this.customFieldMetadata.valueValidation.min = null;
      this.customFieldMetadata.valueValidation.max = null;
    },

    emitSequenceChanges() {
      this.$emit('input', this.customFields);
    },

    optionSettings(field) {
      this.tempFieldMetadata = field;
      this.isAddingOptions = true;
    },

    async saveField() {
      try {
        await configApi.saveCustomFormField(this.customFields);
        this.$myalert.success('Custom fields saved successfully');
        this.$emit('saved', true);
      } catch (error) {
        this.$myalert.error(error?.message);
      }
    },

    dialogStatus(val) {
      if (!val) {
        this.clearForm();
      }
    },

    lengthValidation($v) {
      if (!$v.$invalid || !this.isSubmitted) return [];

      if ($v.max.$invalid) return ['Max length should be greater than min length'];
      else if ($v.min.$invalid) return ['Min length should be less than max length'];
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: 0.75rem;
  font-weight: bold;
}
</style>
